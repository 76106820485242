
import { Vue, Component } from 'vue-property-decorator';
import http from '@/api/http';
import { Tabs, TabPane, Backtop } from 'element-ui';
@Component({
 components: {
  [Tabs.name]: Tabs,
  [TabPane.name]: TabPane,
  [Backtop.name]: Backtop,
 },
})
export default class DoctorList extends Vue {
 public list: any[] = [];
 public depList: any[] = [];
 public doctorList: any[] = [];
 public activeId = '-1';

 public handleClick(tab: any) {
  this.getdoctr(tab.name);
 }
 public getdoctr(id: any) {
  http.post('/uapi/doctor/md/list', { departmentId2: id, hospitalLevel: 1, ps: 50 }).then((res) => {
   this.doctorList = res.result.list;
  });
 }
 public created() {
  // http.get('/uapi/article/md/getByIds?ids=100014,100015').then((res) => {
  //   this.list = res.result.list;
  // });
  http.post('/uapi/department/md/list', { gtDoctorNum: 1, pid: 50 }).then((res) => {
   // this.depList = res.result.list;
   let resList = res.result.list;
   this.depList = resList.filter((department: any) => {
    return department.id === 119 || department.id === 118 || department.id === 101 || department.id === 109;
   });
   this.depList.unshift({
    id: '-1',
    name: '全部',
   });
  });
  http.post('/uapi/doctor/md/list', { departmentId2: this.activeId, hospitalLevel: 1, ps: 50 }).then((res) => {
   this.doctorList = res.result.list;
  });
 }
}
